import Styled from 'styled-components';

import { baseMixin } from '../mixins';

const Wrapper = Styled.div`
  ${baseMixin};

  .page {
    padding-top: 0;
  }

  .page.header {
    padding: 3em 1em 1em 1em;
  }
`;

export { Wrapper };
