import React, { Component } from 'react';

import Styled from 'styled-components';

class TabViewer extends Component {
  static propTypes = {};

  state = {
    selected: 0,
  };

  getTitle = index => {
    if (this.props.tabs[index].title) {
      return this.props.tabs[index].title;
    } else if (this.props.tabs[index].image) {
      return this.props.tabs[index].image;
    }
  };

  handleTabClick = ev => {
    const newId = parseInt(ev.target.dataset.id);
    this.setState({ selected: newId });
  };

  render() {
    return (
      <TabViewerWrapper>
        <div className="tab-viewer__tabs-container">
          {this.props.tabs.map((_, index) => (
            <span
              key={index}
              data-id={index}
              className={
                this.state.selected === index
                  ? 'tab-viewer__tab tab-viewer__tab--active'
                  : 'tab-viewer__tab'
              }
              onClick={this.handleTabClick}
            >
              <span>{this.getTitle(index)}</span>
            </span>
          ))}
        </div>
        <div className="tab-viewer__content">
          {this.props.tabs[this.state.selected].text}
        </div>
      </TabViewerWrapper>
    );
  }
}

/*
style={{
                width: window.innerWidth / this.props.tabs.length - 1,
              }}
*/

const TabViewerWrapper = Styled.div`
  .tab-viewer__tabs-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    .tab-viewer__tab {
      background-color: rgb(250, 250, 250);
      border-bottom: 1px solid rgb(240, 240, 240);
      border-right: 1px solid rgb(240, 240, 240);
      cursor: pointer;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 12px;
      padding-bottom: 12px;
  
      span {
        pointer-events: none;
      }
    }

    .tab-viewer__tab--active {
      background-color: rgb(255, 255, 255);
      border-top: 1px solid #e5665d;
      border-bottom: none;
      cursor: auto;
    }
  }
  .tab-viewer__content {
    background-color: rgb(255, 255, 255);
    padding: 2em;
  }
`;

/*

.tab-viewer__tabs-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }



.tab-viewer__tab {
  width: 100%;
  height: 100%;

  span {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    clear: both;
    display: inline-flex;

    img {
      max-height: 100px;
    }
  }
}

*/

// const TabViewerWrapper = Styled.div`
//   .tab-viewer__tabs-container:after {
//     display: table;
//     content: '';
//     clear: both;
//   }

//   .tab-viewer__tabs-container > span {
//     display: block;
//     float: left;
//     height: 100%;
//     padding: 15px 0;
//     text-align: center;
//   }

//   .tab-viewer__tab {
//     background-color: rgb(250, 250, 250);
//     border-bottom: 1px solid rgb(240, 240, 240);
//     border-right: 1px solid rgb(240, 240, 240);
//     cursor: pointer;
//     height: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     height: 100%;

//     span {
//       pointer-events: none;
//     }
//   }

//   .tab-viewer__tab img {
//     max-height: 100px;
//     text-align: center;
//     vertical-align: middle;
//   }

//   .tab-viewer__tab--active {
//     background-color: rgb(255, 255, 255);
//     border-top: 1px solid #e5665d;
//     border-bottom: none;
//     cursor: auto;
//   }

//   .tab-viewer__content {
//     background-color: rgb(255, 255, 255);
//     padding: 2em;
//   }
// `;

export default TabViewer;
