// Core imports
import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

// Utility imports
import Layout from '../layouts/mainLayout';

// Component imports
import TabViewer from '../components/TabViewer';

// Asset imports
import { Wrapper } from '../styles/pages/certifications-and-associations';

export const query = graphql`
  query certsAndAssocPageQuery {
    iicrc: file(relativePath: { regex: "/logo-iicrc.jpg/" }) {
      childImageSharp {
        fixed(width: 86, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mspca: file(relativePath: { regex: "/logo-mspca.jpg/" }) {
      childImageSharp {
        fixed(width: 90, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    cri: file(relativePath: { regex: "/logo-cri.jpg/" }) {
      childImageSharp {
        fixed(width: 56, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const CertificationsAndAssociationsPage = ({ data }) => {
  const tabs = [
    {
      image: <Img fixed={data.iicrc.childImageSharp.fixed} />,
      text:
        'The Institute of Inspection, Cleaning and Restoration are the most recognized body of certification for carpet and upholstery cleaners in the world. In order to be certified, you must attend a very in-depth course followed by an exam that must be passed.',
    },
    {
      image: <Img fixed={data.mspca.childImageSharp.fixed} />,
      text:
        'Mid-South Professional Cleaners Association is a non-profit association of specialists dedicated to the art of cleaning and restoration.',
    },
    {
      image: <Img fixed={data.cri.childImageSharp.fixed} />,
      text:
        'CRI Carpet and Rug Institute – Approved Service Provider Those cleaning companies listed as certified Seal of Approval service providers have met the qualifications which assure you their cleaning equipment and products are among the very best in the market.',
    },
  ];

  return (
    <Layout>
      <Wrapper>
        <h1 style={{ marginTop: 0 }}>Certifications and Associations</h1>

        <TabViewer tabs={tabs} />
        <div className="page">
          <h2>
            Sci-Tech Carpet Cleaning's staff holds the following certifications:
          </h2>
          <ul>
            <li>Carpet Cleaning</li>
            <li>Rug Cleaning</li>
            <li>Upholstery and Fabric Cleaning</li>
            <li>Leather Cleaning</li>
            <li>Odor Control</li>
            <li>Water Damage Restoration</li>
            <li>Journeyman Textile Cleaner</li>
            <li>Journeyman Water Restorer</li>
          </ul>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default CertificationsAndAssociationsPage;
